/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import qrCode from "assets/img/qr-code.png";
import itaa from "assets/img/ITAA.png";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.column}>
            <h4 className={classes.footerTitle}>
              Integrale Bedrijfsbegeleiding
            </h4>
            <div className={classes.rowNoSpace}>
              <i className={"fas fa-map-marker-alt"} />
              <div>
                Mechelsestraat 2 <br />
                2260 Westerlo
              </div>
            </div>
            <div className={classes.rowNoSpace}>
              <i className={"fas fa-phone fa-flip-horizontal"} />
              <div>
                <a href="tel:+3214808383">014/80 83 83</a>
              </div>
            </div>
            <Link to="/afspraken">
              <Button color="primary">Maak een afspraak</Button>
            </Link>
        <div className={classes.iconLine}>
        <Tooltip
          id="telephone-tooltip"
          title="Contacteer ons telefonisch"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <span>
            <Button
              color="transparent"
              href="tel:+3214808383"
              title="Neem telefonisch contact op"
              className={classes.navLinkIcon}
            >
              <i className={classes.socialIcons + " fas fa-phone fa-flip-horizontal"} />
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          id="mail-tooltip"
          title="Contacteer ons via email"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <span>
            <Button
              color="transparent"
              href="mailto:info@integralebedrijfsbegeleiding.be"
              title="Neem contact op via email"
              className={classes.navLinkIcon}
            >
              <i className={classes.socialIcons + " fa fa-envelope"} />
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          id="facebook-tooltip"
          title="Volg ons op Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <span>
            <Button
              color="transparent"
              href="https://www.facebook.com/IntegraleBedrijfsbegeleiding/"
              title="Volg ons op facebook"
              target="_blank"
              className={classes.navLinkIcon}
            >
              <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          id="mail-tooltip"
          title="Volg ons op LinkedIn"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <span>
            <Button
              color="transparent"
              href="https://be.linkedin.com/company/boekhoudkantoor-integrale-bedrijfsbegeleiding"
              title="Volg ons op LinkedIn"
              target="_blank"
              className={classes.navLinkIcon}
            >
              <i className={classes.socialIcons + " fab fa-linkedin"} />
            </Button>
          </span>
        </Tooltip>
        </div>
          </div>
          <div>
            <h4 className={classes.footerTitle}>Sitemap</h4>
            <List className={classes.column}>
              <ListItem className={classes.inlineBlock}>
                <Link to="/" className={classes.block}>
                  Home
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/diensten" className={classes.block}>
                  Diensten
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/over-ons" className={classes.block}>
                  Over ons
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/team" className={classes.block}>
                  Team
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/contact" className={classes.block}>
                  Contact
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/tipsandtricks" className={classes.block}>
                  Tips &amp; Tricks
                </Link>
              </ListItem>
            </List>
          </div>
          <div>
            <h4 className={classes.footerTitle}>Meer info</h4>
            <List className={classes.column}>
              <ListItem className={classes.inlineBlock}>
                <Link to="/afspraken" className={classes.block}>
                  Maak een afspraak
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://live.getsilverfin.com/global_users/sign_in"
                  className={classes.block}
                  target="_blank"
                >
                  Silverfin
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/legal" className={classes.block}>
                  Legal &amp; privacy
                </Link>
              </ListItem>
            </List>
          </div>
          <div className={classes.column}>
            <h4 className={classes.footerTitle}>Openingsuren</h4>
            <div className={classes.row}>
              <div className={classes.rowopeningsuren}>
                <i className="far fa-clock"></i>
                <p className={classes.bolder}>Maandag-donderdag:</p>
              </div>
              <div>
                <p>08:00 - 12:00 en 13:00 - 17:00u</p>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.rowopeningsuren}>
                <i className={"far fa-clock "}></i>
                <p className={classes.bolder}>Vrijdag:</p>
              </div>
              <div>
                <p>08:00 - 12:00 en 13:00 - 15:00u</p>
              </div>
            </div>
            <div
              className={classes.row}
              style={{ borderBottom: "1px solid #999" }}
            >
              <div className={classes.rowopeningsuren}>
                <p className={classes.bolder}>
                  Zaterdag, zondag &amp; feestdagen gesloten
                </p>
              </div>
            </div>
            <div className={classes.logo}>
              <a target="_blank" rel="noreferrer" href="https://www.itaa.be/">
                <img src={itaa} width={200} />
              </a>
            </div>
          </div>
        </div>
        <div className={classes.flexright}>
          <p className={classes.copyright}>
            &copy; {1900 + new Date().getYear()} made by{" "}
            <a target="_blank" href="https://www.jitsebeyens.be">
              JB Solutions
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
