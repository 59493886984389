import { container, primaryColor } from "assets/jss/material-kit-react.js";

const footerStyle = {
  block: {
    color: "inherit",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important",
  },
  footer: {
    padding: "0.9375rem 0",
    zIndex: "2",
    position: "relative",
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF",
    },
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px",
  },
  footerImg: {
    height: "70px",
  },
  clear: {
    clear: "both",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "20px",
  },
  rowNoSpace: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    flexWrap: "wrap",
  },
  rowopeningsuren: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    flexWrap: "wrap",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: "10px",
  },
  copyright: {
    margin: "0px",
    padding: "0px",
  },
  footerTitle: {
    color: primaryColor,
    fontWeight: "bolder",
  },
  bolder: {
    fontWeight: "bolder",
  },
  flexright: {
    display: "flex",
    justifyContent: "right",
  },
  navLinkIcon: {
    color: "black",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:visited": {
      color: "black",
    },
    "&:hover,&:focus": {
      color: "#1292dd",
      background: "rgba(200, 200, 200, 0.2)",
    
    },
  },
  iconLine:{
    display: "flex"
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
  },
};
export default footerStyle;
